




















































































































































































































































import { downloadFileURL } from '@/shared/helpers'
import { ICertificate } from '@/shared/model/certificate.model'
import { IUser, User } from '@/shared/model/user.model'
import userService from '@/shared/services/userService'
import dayjs from 'dayjs'
import Vue from 'vue'
// import QrcodeVue from 'qrcode.vue'

export default Vue.extend({
  // components: {
  //   QrcodeVue,
  // },
  data () {
    return {
      user: {} as IUser,
      image: null,
      certs: undefined as any[] | undefined,
      eval1: false,
      eval2: false,
      eval3: false,
      breadcrumbItems: [
      {
        text: this.$t('coworker').toString(),
        to: '/app/coworker',
        exact: true,
      },
    ] as any[],
    }
  },
  computed: {
    publicProfileRoute (): string {
      var host = window.location.protocol + '//' + window.location.host
      return host + '/public/' + this.$route.params.uuid
    },
    headers (): any {
      return [
        {
          text: this.$t('certification-authority'),
          value: 'certification-authority',
        },
        {
          text: this.$t('date'),
          value: 'date',
        },
        {
          text: this.$t('due-date'),
          value: 'dueDate',
        },
        {
          text: this.$t('name'),
          value: 'name',
        },
        {
          text: this.$t('issued-by'),
          value: 'authorObject.username',
        },
        {
          text: this.$t('level'),
          value: 'certificateTemplateObject.level',
        },
        {
          sortable: false,
          text: this.$t('action'),
          value: 'action',
          align: 'end',
        },
      ]
    },
  },
  created () {
    const uuid = this.$route.params.uuid

    if (uuid) {
      userService
        .getCoworkerProfile(uuid)
        .then((user: any) => {
          this.user = user
          if (user && user.certificates) {
            this.breadcrumbItems.push({
              text: Vue.filter('fullNameOfUser')(user).toString(),
            })
            this.certs = user.certificates
            if (this.certs) {
              for (const cert of this.certs) {
                if (cert.certificateTemplateObject) {
                  if (
                    !this.certificateIsOverDueDate(cert.dueDate) &&
                    cert.certificateTemplateObject.level === 'EVAL-1'
                  ) {
                    this.eval1 = true
                  }
                  if (
                    !this.certificateIsOverDueDate(cert.dueDate) &&
                    cert.certificateTemplateObject.level === 'EVAL-2'
                  ) {
                    this.eval2 = true
                  }
                  if (
                    !this.certificateIsOverDueDate(cert.dueDate) &&
                    cert.certificateTemplateObject.level === 'EVAL-3'
                  ) {
                    this.eval3 = true
                  }
                }
              }
            }
          }
        })
        .catch((e) => {
          console.error('getPublicProfile() failed', e)
          this.$router.push('/not-found', () => {})
        })
    }
  },
  methods: {
    gotoprint (certificate: ICertificate) {
      if (certificate && certificate.pdfFile) {
        downloadFileURL(`/api/file/${certificate.pdfFile}`, 'Zertifikat', 'application/pdf')
      }
    },
    certificateIsOverDueDate (date: Date) {
      return dayjs(date).isBefore(new Date())
    },
  },
})
